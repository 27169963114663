<template>
  <div class="container-fluid">
      <b-row>
        <b-col sm="12" md="7" lg="7" xl="7" class="bg-resetpassword align-items-center d-none d-lg-flex">
          <div class="ml-5">
            <img alt="WYA Yoga" src="../../assets/images/logo/logo.png">
            <h1 class="text-white mt-3 font-weight-bold display-3">{{ $t('WORLD YOGA ALLIANCE') }}</h1>
            <h5 class="text-white mt-4">{{ $t('International Yoga Organization') }}</h5>
            <br>
            <b-button pill variant="light" :to="{ name: 'home' }" class="mr-3 px-5 my-4 font-weight-bold header-btn-register">{{ $t('Learn more') }}</b-button>
            <b-button pill variant="outline-light" :to="{ name: 'home' }" class="mr-3 px-5 my-4 ml-2 font-weight-bold header-btn-register">{{ $t('Search') }}</b-button>
          </div>
        </b-col>
        <b-col sm="12" md="5" lg="5" xl="5" class="d-flex justify-content-center align-items-center">
          <div class="mt-5 mt-lg-0">
            <div class="d-flex justify-content-center"><h1 class="text-primary-light d-inline font-weight-bold header-login">{{ $t('Confirm Reset Password') }}</h1></div>
            <p class="text-center text-gray-light mt-5" v-html="$t('Enter your new password')"></p>
            <router-link :to="{ name: 'authenResetpassword' }" class="d-block text-danger-light mt-3 text-center">{{ $t('You didnt receive the email reset password?') }}</router-link>
            <div class="mt-5">
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
                  <validation-provider
                    :name="$t('New Password')"
                    rules="required|min:8|confirmed:form.new_password2"
                    v-slot="{errors}"
                  >
                  <b-form-group class="font-weight-bold text-primary-dark" for="input-newpassword1" :label="$t('New Password')">
                    <b-form-input
                      id="input-newpassword1"
                      class="input-login"
                      type="password"
                      v-model="form.new_password1"
                      :placeholder="$t('New Password')">
                    </b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                  </validation-provider>
                  <validation-provider
                    :name="$t('Confirm New Password')"
                    rules="required|min:8"
                    v-slot="{errors}"
                    vid="form.new_password2"
                  >
                  <b-form-group class="font-weight-bold text-primary-dark" for="input-newpassword2" :label="$t('Confirm New Password')">
                    <b-form-input
                      id="input-newpassword2"
                      class="input-login"
                      type="password"
                      v-model="form.new_password2"
                      :placeholder="$t('Confirm New Password')">
                    </b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                  </validation-provider>
                  <b-button pill block type="submit" class="mr-3 mt-2 px-5 my-4 font-weight-bold btn-primary-dark"><b-spinner small class="mr-3" v-if="isLoading"  label="Loading..."></b-spinner>{{ $t('Confirm') }}</b-button>
                  <small class="text-danger" v-if="textError" v-html="textError"></small>
                </b-form>
              </ValidationObserver>
              <router-link :to="{ name: 'authenSignup' }" class="d-block text-danger-light mt-3 text-center">{{ $t('Create New Account') }}</router-link>
            </div>
            <div class="text-center">
              <span class="text-gray-light d-block mt-5">
                Powered By Government of INDIA and THAILAND <br>
                <small class="text-gray-light">Copyright © World Yoga Alliance® 2011 - {{ currentYear }}</small>
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
  </div>
</template>
<script>
import Api from '../../api/api'

export default {
  name: 'ConfirmResetPassword',
  title: ' | Confirm Reset Password',
  data () {
    return {
      currentYear: new Date().getFullYear(),
      form: {
        new_password1: '',
        new_password2: '',
        uid: '',
        token: ''
      },
      textError: '',
      isLoading: false
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.form.uid = this.$route.params.uid
    this.form.token = this.$route.params.token
    if (this.form.uid === null || this.form.uid === null) {
      this.$notify({
        group: 'warning',
        text: this.$t('Invalid data reset password, Please try again')
      })
      this.$router.push({ name: 'authenResetpassword' })
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate().then(result => {
        if (result) {
          this.isLoading = true
          Api.confirmResetpassword(this.form)
            .then(response => {
              if (response.status === 200) {
                requestAnimationFrame(() => {
                  this.$refs.form.reset()
                })
                this.$notify({
                  group: 'success',
                  text: this.$t('Reset new password successfully')
                })
                this.$router.push({ name: 'authenLogin' })
              }
            })
            .catch((error) => {
              this.isLoading = false
              let txtError = ''
              console.log(error.response.data)
              if (error.response.data) {
                for (const data in error.response.data) {
                  txtError += error.response.data[data].join('<br>') + '<br>'
                }
                this.textError = txtError
              } else {
                this.textError = this.$t('Invalid data')
              }
            })
        }
      })
    }
  }
}
</script>
<style scoped>
  .bg-resetpassword {
    background-image: url('../../assets/images/banner/bg_resetpassword.png');
    background-repeat: no-repeat;
    background-size: auto;
    min-height: 900px;
  }
</style>
